<template>
  <div class="textarea-rich">
    <div
      class="textarea-rich-box"
      textarea
      :placeholder="placeholder"
      contenteditable="true"
      v-html="content"
      :name="name"
      @input="$emit('input', $event)"
      @click="$emit('click', $event)"
      @focus="$emit('focus', $event)"
      @blur="$emit('blur', $event)"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    name: null,
    placeholder: null,
    content: null,
  },
};
</script>

<style lang="scss">
.textarea-rich {
  width: 100%;
  &-box {
    width: 100%;
    min-height: 70px;
    padding: $mpadding/2;
    border-radius: $mradius/2;
    border: solid 1px $alto;
    font-size: 100%;
    font-family: $primary_font;
    margin-top: 5px;
    line-height: normal;
    background: $white;
    cursor: text;
    &:empty::before {
      content: attr(placeholder);
      opacity: 0.75;
      pointer-events: none;
    }
  }
}
</style>
